function Home(props) {
    return( 
    <div className="home-page-content">
        <h1>i'm <span className="kate">KATE.</span></h1>
        <p className="intro">a mindful software engineer.</p>

    </div>
    )
}

export default Home;

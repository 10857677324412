function Footer(props) {
    return (
        <footer>
            <p>© Katherine Sprague 2023</p>
        </footer>
    )

}

export default Footer;
